@use '../variables' as *;
@use '../apps/mixins/utilities';
@use "sass:map";

// **********************************************************
// ALBUM ITEM
.albumItem {
    &, &.ui.card {
        .ui.image {
            border: 2px solid transparent !important;
            transition: border $transition-fast;
            cursor: pointer;
            &:hover, &:focus-within {
                border-color: var(--#{$prefix}primary-txt) !important;
            }
        }
    }
}

// **********************************************************
// MODAL
.galleryModal {
    .image.content {
        margin: 0;
    }
    &__btn {
        &, &.ui.button {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            width: 3.5rem;
            height: 3.5rem;
            padding: 0;
            margin: 0;
            border-radius: 50%;
            background-color: rgba(var(--#{$prefix}dark-rgb), 0.66);
            color: var(--#{$prefix}white-txt);
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            z-index: 10;
            svg {
                height: 1.5rem;
                width: auto;
            }
            &:hover, &:focus {
                background-color: var(--#{$prefix}primary-bg);
            }
        }
        &--prev {
            left: 2rem;
        }
        &--next {
            right: 2rem;
        }
    }
    &__image {
        position: relative;
        z-index: 1;
        width: 100%;
        height: auto;
    }
}

.ui.fullscreen.modal > .close {
    width: 2.5rem; height: 2.5rem;
    font-size: 1.5rem;
    z-index: 100;
    border: 1px solid var(--#{$prefix}white-txt);
    color: var(--#{$prefix}white-txt);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    background-color: var(--#{$prefix}primary-bg);
}