@use '../variables' as *;
@use "sass:map";

// **********************************************************
// RELATED CONTENT
.relatedContent {
    --#{$prefix}related-bg-color: var(--#{$prefix}primary);
    --#{$prefix}related-text-color: var(--#{$prefix}body-color);
    --#{$prefix}related-link-color: var(--#{$prefix}body-color);
    --#{$prefix}related-icon-color: var(--#{$prefix}white-txt);
    --#{$prefix}link-color: var(--#{$prefix}white);
    --#{$prefix}link-hover-color: var(--#{$prefix}secondary);

	&.ui.basic.segment {
		color: var(--#{$prefix}related-text-color);
		// background: url(../img/rodonetes-related.jpg) no-repeat top right / cover var(--#{$prefix}related-bg-color);
		background-blend-mode: multiply;
        margin-bottom: 0;
	}
	&__title {
		&.ui.header {
			color: var(--#{$prefix}related-text-color);
            font-size: $h5-font-size;
		}
	}
	&__section {
		&.ui.segment {
			padding-left: 0;
			padding-right: 0;
		}
	}
    .userBlocks + & {
        margin-top: 5rem;
    }
}

// **********************************************************
// RELATED DOCUMENT & LINK
.relatedItem {
    transition: $transition-base;
    min-height: 4rem;
    display: grid;
    grid-template-columns: 4rem 1fr;
    grid-template-rows: auto auto;
    gap: 0 1rem;
    color: var(--#{$prefix}related-link-color);
    text-decoration: none;
    width: 100%;
    font-size: $h6-font-size;
    line-height: $line-height-sm;
	margin-bottom: 1.5rem;
    &__icon {
        width: 4rem;
        height: 4rem;
        background-color: var(--#{$prefix}related-bg-color);
        color: var(--#{$prefix}related-icon-color);
        display: inline-flex;
        justify-content: center;
        border-radius: var(--#{$prefix}border-radius);
        align-items: center;
        grid-column: 1 / 2;
        grid-row: 1/3;
        svg {
			height: 1.5rem;
		}
    }
    &__text {
        font-weight: $font-weight-bold;
        grid-column: 2/3;
        grid-row: 1/2; 
		&-links {
			font-weight: $font-weight-bold;
			margin-top: 0;
			align-self: center;
			grid-row: 1 / 3;
		}
    }
    &__size {
        opacity: .8;
        font-size: calc(.875em * var(--#{$prefix}fs-scale));
        grid-column: 2/3;
        grid-row: 2/3;
        margin-top: 0.25rem;
        align-self: end;
    }
    &:hover, &:focus {
        text-decoration: none; 
        color: var(--#{$prefix}primary-txt);
        .relatedItem__icon {
            background-color: var(--#{$prefix}dark);
            color: var(--#{$prefix}primary);
            border: 1px solid var(--#{$prefix}dark);
        }
    }
}


// **********************************************************
// RELATED MEDIA
.relatedMedia {    
    --#{$prefix}lightboxColor: var(--#{$prefix}white);
    .lightBoxWrapper {
        display: grid;
        gap: 2rem;
        
        @media (min-width: map.get($grid-breakpoints, 'sm') ) {
            grid-template-columns: repeat(2, 1fr);    
        }
        // 1280 pixels - 80em
        @media (min-width: map.get($grid-breakpoints, 'xl') ) {
            grid-template-columns: repeat(3, 1fr);
        }
         
    }
    .lightBoxItem {
        &__img {
            transition: $transition-base;
            aspect-ratio: 16 / 9;
            object-fit: cover;
            &:first-child {
                border-radius: var(--#{$prefix}border-radius-sm) !important;
            }
        }
        &:hover, &:focus-within {
            .lightBoxItem__img {
                opacity: .66;
            }
        }
        &__link.ui.button {
            &:hover, &:focus {
                text-decoration: underline;
                background-color: transparent;
                color: var(--#{$prefix}--bs-secondary)
            }
        }
    } 
}
