@use '../variables' as *;
@use "sass:map";

// **********************************************************
// LAYOUT
.ui.breadcrumbs {
    body.noBreadcrumbs & {
        display: none !important;
    }
    &.vertical.segment {
        padding: 0;
        border-bottom: none;
    }
    &.secondary.segment {
        position: relative !important;
        font-size: $h6-font-size;
        background-color: var(--#{$prefix}white-bg);
        color: rgba(var(--#{$prefix}dark-rgb), .6);
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        min-height: 12.5rem;
        margin-bottom: 4rem;
        // 768 pixels - 48em
        @media (min-width: map.get($grid-breakpoints, "md")) {
            align-items: flex-start;
        }
    }
    .breadcrumbs__bg {
        position: absolute;
        top: 0;height: 100%;
        left: 0;width: 100%;
        z-index: 0;
        object-fit: cover;
    }
    // **********************************************************
    // LIST
    .ui.breadcrumb {
        position: relative;
        z-index: 1;
        background-color: var(--#{$prefix}white-bg);
        padding: .5rem 1em;
        font-size: $font-size-sm;
        flex-wrap: nowrap;
        a.section, a {
            color: rgba(var(--#{$prefix}dark-rgb), .6);
            text-decoration: underline;
            text-underline-offset: .25em;
            text-decoration-color: rgba(var(--#{$prefix}dark-rgb), .33);
            &.home {
                color: var(--#{$prefix}primary-txt);
            }
            &:hover, &:focus {
                color: var(--#{$prefix}primary-txt);
                text-decoration: underline;
            }
        }
        li {
            margin-right: .5rem;
            &:has(.active) {
                overflow: hidden;
            }
        }
        li + li {
            display: inline-flex;
            align-items: center;
            &::before {
                content:'';
                margin-right: .5rem;
                border-left: 1px solid currentColor;
                height: $line-height-base * 1em;
                display: inline-block;
                width: 1px;
            }
        }
        .section.active {
            font-weight: $font-weight-bold;
            color: var(--#{$prefix}body-color);
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    
        // 768 pixels - 48em
        @media (min-width: map.get($grid-breakpoints, "md")) {
            padding-left: calc(50vw - ( var(--#{$prefix}current-breakpoint) / 2 ) );
            font-size: $font-size-base;
            li {
                margin-right: 1rem;
            }
            li + li {
                &::before {
                    content:'';
                    margin-right: 1rem;
                }
            }
        }
    }
}
