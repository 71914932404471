@use '../variables' as *;
@use '../apps/mixins/utilities';
@use "sass:map";

// **********************************************************
// LAYOUT
#contact-form {

    form.ui.form {
        max-width: 60rem;
        margin-left: auto;
        margin-right: auto;
        .ui.segments {
            box-shadow: none;
            border: none;
    
            &>.ui.segment {
                background-color: var(--#{$prefix}light);
                padding-top: 0 !important;
            }
        }
        .headerForm.ui.segment {
            background-color: var(--#{$prefix}gray-200);
            margin-left: -1rem;
            margin-right: -1rem;
            border: none;
            box-shadow: none;
        }
        
          // **********************************************************
          // FIELDS
         .field {
              margin-bottom: 1.5rem;
              .row {
                  flex-direction: column;
                  .column.wide {
                      width: 100% !important;
                  }
              }
          
              .wrapper {
                  height: auto;
                  min-height: 0;
                  margin-top: 0 !important;
          
                  &>label {
                      font-weight: $font-weight-bold;
                      font-size: $font-size-base;
                      margin-bottom: 0.5rem;
                  }
              }
          
              .ui.input input:not([type]),
              .ui.input input[type="date"],
              .ui.input input[type="datetime-local"],
              .ui.input input[type="email"],
              .ui.input input[type="number"],
              .ui.input input[type="password"],
              .ui.input input[type="search"],
              .ui.input input[type="tel"],
              .ui.input input[type="time"],
              .ui.input input[type="text"],
              .ui.input input[type="file"],
              .ui.input input[type="url"],
              textarea {
                  border: 1px solid var(--#{$prefix}primary);
                  border-radius: var(--#{$prefix}border-radius);
                  height: 3rem;
                  padding: 0.5rem;
              }
          
              textarea {
                  height: 6rem;
                  margin-top: 0 !important;
              }
              
          }
    }

}
