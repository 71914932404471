@use '../variables' as *;
@use "sass:map";

// **********************************************************
// PHONES
.phones {
    &__wrapper {
        background-color: var(--#{$prefix}primary-bg);
        color: var(--#{$prefix}white-txt);
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
    }

    &__title {

        &,
        &.header.ui {
            color: var(--#{$prefix}white-txt);
        }
    }

    &__list {
        display: grid;
        grid-template-columns: auto 1fr;
        gap: .5rem;
    }

    &__ph {
        font-weight: $font-weight-bold;
        text-align: left;
    }

    &__nu {
        text-align: left;
        margin-left: 0;

        a {
            color: var(--#{$prefix}white-txt);
            text-underline-offset: .2em;
            text-decoration-color: rgba(var(--#{$prefix}white-rgb), .33);
            white-space: nowrap;

            &:hover,
            &:focus {
                color: rgba(var(--#{$prefix}white-rgb), .66);
                text-decoration: underline;
            }
        }
    }

    // 992 pixels - 62em
    @media (min-width: map.get($grid-breakpoints, "lg")) {

        &__list {
            grid-template-columns: repeat(4, auto);
        }

        &__ph {
            text-align: right;
            padding-left: 1.5rem;
        }

    }

    // 1600 pixels - 100em
    @media (min-width: map.get($grid-breakpoints, "xxl")) {
        .container {
            display: flex;
            gap: 1.5rem;
            align-items: center;
        }

        &__title {

            &,
            &.header.ui {
                margin-bottom: 0;
            }
        }

        &__list {
            margin: 0 0 0 auto;
        }
    }
}