@use '../variables' as *;
@use "sass:map";

// **********************************************************
// COOKIE BANNER
.gdpr-privacy-banner {
    background-color: var(--#{$prefix}light);
    z-index: 1100;
}
button.gdpr-privacy-show-banner {
    z-index: 100;
}