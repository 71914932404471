@use '../variables' as *;

// **********************************************************
// ASPECT RATIO
.ratio {
    position: relative;
    width: 100%;
    height: auto;
    object-fit: cover;
}
.ratio-16x9 {
    aspect-ratio: 16 / 9;
}