@use '../variables' as *;
@use '../apps/mixins/utilities';
@use "sass:map";

// **********************************************************
// FRONTPAGE
.basicSearch {
    &__wrapper {
        background-color: var(--#{$prefix}light);
        padding-top: 2rem;
        padding-bottom: 2rem;
    }

    &__title {

        &,
        .ui.basic.segment &.ui.header {
            text-align: center;
            justify-content: center;
        }
    }

    &__group {
        display: flex;
        margin-left: auto;
        margin-right: auto;
        justify-content: center;
    }

    &__input {

        &,
        &.ui.input {
            border-radius: var(--#{$prefix}border-radius-pill) 0 0 var(--#{$prefix}border-radius-pill);
            ;
            border: 1px solid var(--#{$prefix}primary-bg);
            font-family: $font-family-body;

            input {
                border-radius: var(--#{$prefix}border-radius-pill) 0 0 var(--#{$prefix}border-radius-pill) !important;
                padding-left: 1em;

                &[type="search"] {
                    height: auto !important;
                }
            }
        }
    }

    &__btn {

        &,
        &.ui.button {
            border-radius: 0 var(--#{$prefix}border-radius-pill) var(--#{$prefix}border-radius-pill) 0;
        }
    }

    // 768 pixels - 48em
    @media (min-width: map.get($grid-breakpoints, "md")) {
        &__input {

            &,
            &.ui.input {
                input {
                    width: 35rem;
                }
            }
        }
    }
}

// **********************************************************
// MODAL
.modalSearch {
    &.modal.ui {
        &>.content {
            background-color: var(--#{$prefix}white-bg);
        }
    }

    & .searchbox {
        &__label {
            font-weight: $font-weight-bold;
        }
    }

    .ui.form .searchbox {
        &.field {
            border-left: none;
            padding-left: 0;
            margin-top: 1rem;

            button {
                background-color: var(--#{$prefix}primary-bg);
                color: var(--#{$prefix}white-txt);
                padding: 0.5rem 1rem;
                border-radius:0 var(--#{$prefix}border-radius-pill) var(--#{$prefix}border-radius-pill) 0 !important;

                // 992 pixels - 62em
                @media (min-width: map.get($grid-breakpoints, "lg")) {
                    padding: .5rem 1.5rem;
                }
            }
        }

        .ui.input input {
            border: 1px solid var(--#{$prefix}primary-txt) !important;
            padding: .5rem 1.0rem !important;
            border-radius: var(--#{$prefix}border-radius-pill) 0 0 var(--#{$prefix}border-radius-pill) !important;
            background-color: var(--#{$prefix}white-bg) !important;
        }

    }
}

.ui.dimmer {
    z-index: 1080;
}

.section-search .items_total .button-active,
.section-search .items_total .button-sort {
    color: var(--#{$prefix}gray-600);
}

// **********************************************************
// RESULTS LIST
.searchResults {
    &__form {
        &, &.ui.form {
            max-width: 56rem;
            width: 100%;
            margin: 0 auto 1.5rem auto;
        }

        &.ui.form .fields {
            display: block;
            margin: 0;
        }

        &__wrapper {
            display: flex;
            width: 100%;
        }

        &__label {

            &,
            &.ui.label {
                margin-right: 0;
                font-size: $h4-font-size;
                color: var(--#{$prefix}body-color);
                font-weight: $font-weight-bold;
                background-color: transparent;
                text-align: center;
                display: block;
            }
        }

        &__input {
            flex-grow: 1;
            &.field .ui.input input {
                padding-left: 1rem;
                border: 1px solid var(--#{$prefix}primary-txt) !important;
                border-radius: var(--#{$prefix}border-radius-pill) 0 0 var(--#{$prefix}border-radius-pill) !important;
             
            }
        }

        &__btn {
            flex-shrink: 0;

            .button.ui {
                height: 100%;
                border-radius: 0 var(--#{$prefix}border-radius-pill) var(--#{$prefix}border-radius-pill) 0;
                white-space: nowrap;
            }
        }
    }
}

// **********************************************************
// RESULTS LIST ITEM
.searchResultItem {
    &__link {
        text-decoration: none;
        color: var(--#{$prefix}body-color);
        &:hover, &:focus {
            color: var(--#{$prefix}primary-txt);
            text-decoration: underline;
            text-decoration-thickness: 2px;
            text-underline-offset: .125em;
        }
    }
}