@use '../../variables' as *;
@use "sass:map";

// **********************************************************
// LAYOUT
.xxssBlock {
    background-color: var(--#{$prefix}secondary);
    color: var(--#{$prefix}white-txt);

    &__container {
        &, &.ui.container {
            display: flex;
            flex-direction: column;
            padding-top: 2rem;
            padding-bottom: 2rem;
        }
    }

    &__wrapper {
        &, &.ui.basic.segment {
            padding: 0;
            margin: 0;
        }
    }
    
    &__title {
        &, &.ui.header {
            color: var(--#{$prefix}white-txt);
            text-align: center !important;
            justify-content: center !important;
        }
    }

    &__list {
        display: flex;
        justify-content: center;
        gap: 1.5rem;
    }

    &__link {
        display: inline-flex;
        width: 4rem;
        height: 4rem;
        border-radius: 50%;
        justify-content: center;
        align-items: center;
        font-size: 1.5rem;
        background-color: var(--#{$prefix}white-bg);
        &--facebook {
            color: var(--#{$prefix}facebook);
        }
        &--twitter {
            color: var(--#{$prefix}twitter);
        }
        &--linkedin {
            color: var(--#{$prefix}linkedin);
        }
        &--whatsapp {
            color: var(--#{$prefix}whatsapp);
        }
        &--youtube {
            color: var(--#{$prefix}youtube);
        }
        &:hover, &:focus {
            background-color: var(--#{$prefix}primary-bg);
            color: var(--#{$prefix}white-txt);
        }
    }

    // 768 pixels - 48em
    @media (min-width: map.get($grid-breakpoints, "md")) {
        &__container {
            &, &.ui.container {
                flex-direction: row;
                align-items: center;
            }
            & > * {
                flex: 1 1 0;
            }
        }
        &__title {
            &, &.ui.header {
                margin-bottom: 0;
            }
        }
    }
}