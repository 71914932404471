@use '../variables' as *;
@use "sass:map";

// **********************************************************
// LAYOUT
.frontPageInfos .gridBlock,
.frontPageInfos .ui.stackable.grid>.column {
    padding-bottom: 0 !important;
    margin-bottom: 0 !important;
    padding-top: 0 !important;
    margin-top: 0 !important;
    .ui.segment {
        padding-bottom: 0 !important;
        margin-bottom: 0 !important;
        padding-top: 0 !important;
        margin-top: 0 !important;
    }
    // 992 pixels - 62em
    @media (min-width: map.get($grid-breakpoints, "lg")) {
        display: flex;
        flex-direction: column;
    }
}

.usefulInfo {
    background: url(../img/usefulinfo_bg.jpg) no-repeat center center / cover var(--#{$prefix}dark);
    color: var(--#{$prefix}white-txt);
    // FULL WIDTH
    position: relative;
    right: 50%;
    left: 50%;
    width: 100vw;
    max-width: initial;
    margin-right: -50vw;
    margin-left: -50vw;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;

    .ui.header {
        color: var(--#{$prefix}white-txt);
    }
    .block.gridBlock &__title1.ui.header {
        margin-bottom: 1.5rem !important;
    }

    &__wrapper {
        background-color: rgba(var(--#{$prefix}dark-rgb), .5);
        backdrop-filter: blur(7px);
        -webkit-backdrop-filter: blur(7px);
        border-radius: var(--#{$prefix}border-radius-lg);
        margin: 0 .5rem;
        padding: 1rem;
    }
// **********************************************************
// WEATHER
    &__weather {
        &__live {
            margin: 1rem auto;
            text-align: center;

            &__img {
                height: 4rem;
                width: auto;
            }

            &__link {
                text-align: center;

                a {
                    color: var(--#{$prefix}white-txt);
                    &:hover, &:focus {
                        color: var(--#{$prefix}white-txt);
                        text-decoration: underline;
                        opacity: .8;
                    }
                }
            }
        }
        .ui.padded.table th, .ui.padded.table td {
            padding: 0.5em;
        }

        &__data {
            thead td {
                background-color: rgba(0, 0, 0, 0.15);
                border-bottom: 1px solid white !important;
            }
            &.ui.table.inverted {
                background-color: transparent;
                margin-bottom: 1.5rem;

                thead th {
                    font-weight: $font-weight-bold;
                }
            }
            abbr {
                text-decoration: none;
            }
        }
    }

// **********************************************************
// AIR
    &__aire {
        text-align: center;

        .usefulInfo__title2.ui.header {
            text-align: center;
            justify-content: center;
        }

        &__list {
            margin-top: 0;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            margin-bottom: 1.5rem;

            &>* {
                flex-shrink: 0;
                margin: .5rem;
            }
        }

        .green-circle::before, .orange-circle::before, .red-circle::before, .white-circle::before, .grey-circle::before {
            content: '';
            display: inline-block;
            height: 0.8rem;
            width: 0.8rem;
            border-radius: .8rem;
        }
        .green-circle::before {     background-color: #00CFAC; }
        .orange-circle::before {    background-color: #FF8903; }
        .red-circle::before {       background-color: #FC3544; }
        .white-circle::before {     background-color: $white; }
        .grey-circle::before {     background-color: grey; }

    }


    // 768 pixels - 48em
    @media (min-width: map.get($grid-breakpoints, "md")) {
        margin-left: 0;
        margin-right: 0;
        transform: none;
        width: auto;
        position: static;
    }

    // 992 pixels - 62em
    @media (min-width: map.get($grid-breakpoints, "lg")) {
        width: calc(50vw - 2rem);
        min-height: 100%;
        &__wrapper {
            padding: 2.5rem;
            & > * {
                max-width: 40rem;
                margin-left: auto !important;
                margin-right: auto !important;
            }
        }
        .usefulInfo__title2.ui.header {
            margin-left: auto !important;
            margin-right: auto !important;
            justify-content: flex-start;
            text-align: left;
        }
        &__weather {
            display: flex;
            align-items: center;
            gap: 2rem;
            &__live {
                flex: 0 0 25%;
            }
            &__date{
                &.ui.padded.table th, &.ui.padded.table td {
                    padding: 0.5rem;
                }

            }
        }
    }
}

// **********************************************************
// IFRAME
iframe[name="meteoct"] {
    border: none;
    width: 100%;
    height: 49rem;
}