@use '../variables' as *;
@use '../apps/mixins/utilities';
@use "sass:map";

// **********************************************************
// LAYOUT
.portalFooter {
    background-color: var(--#{$prefix}primary-bg);
    color: var(--#{$prefix}white-txt);

    // 768 pixels - 48em
    @media (min-width: map.get($grid-breakpoints, "md")) {
        display: flex;
        flex-wrap: wrap;
    }
}

// **********************************************************
// LOGO
.footerLogo {
    &__wrapper {
        background-color: var(--#{$prefix}white-bg);
        text-align: center;
        padding: 2rem 2rem 1.5rem 2rem;
        flex: 0 0 auto;
    }

    &__img {
        height: 8rem;
        width: auto;
        margin-bottom: 1rem;
    }
}

// **********************************************************
// XXSS
.footerXXSS {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: .5rem;
    margin: 0;

    &__link {
        @include utilities.clean-button;
        color: var(--#{$prefix}grey);
        font-size: 1.25rem;
        border: 2px solid currentColor;
        border-radius: 50%;

        &:hover,
        &:focus {
            color: var(--#{$prefix}primary-txt);
        }
    }
}

// **********************************************************
// INFO 
.footerInfo {

    &,
    &.ui.segment {
        display: flex;
        flex-wrap: wrap;
        gap: 2rem;
        justify-content: center;
        flex: 1 1 0;
    }

    &__item {
        display: flex;
        align-items: center;

        &__text,
        &__img {
            flex-shrink: 0;
        }

        &__img {
            opacity: .7;
            width: 2.5rem;
            height: auto;
            margin-right: 1rem;
        }

        a {
            text-decoration: none;
            color: var(--#{$prefix}white-txt);
        }
    }

    // 1280 pixels - 75em
    @media (min-width: map.get($grid-breakpoints, "xl")) {

        &,
        &.ui.segment {
            flex-wrap: nowrap;
            justify-content: center;
            align-items: center;
            gap: 4rem;
        }
    }
}


// **********************************************************
// NEWSLETTER
.footerNewsletter {
    text-align: center;

    &__btn.ui.button {
        background-color: var(--#{$prefix}body-color);
        color: var(--#{$prefix}white-txt);
    }

    // 1280 pixels - 75em
    @media (min-width: map.get($grid-breakpoints, "xl")) {
        max-width: 15rem;
    }
}

// **********************************************************
// SUBFOOTER
.footerNav {
    background-color: var(--#{$prefix}black);
    color: var(--#{$prefix}white-txt);
    padding-top: 1rem;
    padding-bottom: 1rem;
    font-size: $font-size-base;

    &__menu {
        margin-top: 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        .item {
            margin-left: 0.5rem;
            margin-right: 0.5rem;
            color: var(--#{$prefix}white-txt);
            text-decoration: none;
        }
    }

    // 768 pixels - 48em
    @media (min-width: map.get($grid-breakpoints, "md")) {
        flex: 0 0 100%;
    }
}

// **********************************************************
// COPYRIGHT
.footerCopy {
    margin-bottom: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    img {
        height: 1.5em;
        height: $line-height-base * 1em;
        width: auto;
    }
}

// **********************************************************
// BANNERS
.bannersFooter {

    &,
    &.ui.segment.basic {
        background-color: var(--#{$prefix}light);
        margin-bottom: 0;
    }

    &__title {

        &,
        &.ui.header {
            margin-top: calc(2.5rem - 1em);
            text-align: center;
            justify-content: center !important;
        }
    }

    .slider-wrapper {
        --slide-size: 100%;
        --slide-spacing: 1rem;
        --slide-height: auto;

        margin-bottom: calc(2.5rem - 1em);
        position: relative;
    }

    .slider__slide {
        z-index: 1;
    }

    .slideControls {

        .slider__button {
            @include utilities.clean-button;
            position: absolute;
            width: 3.5rem;
            height: 3.5rem;
            border-radius: 50%;
            background-color: rgba(var(--#{$prefix}dark-rgb), .66);
            color: var(--#{$prefix}white-txt);
            cursor: pointer;
            top: 50%;
            transform: translateY(-50%);
            z-index: 2;

            &__svg {
                height: 1.5rem;
                width: auto;
            }

            &--prev {
                left: .5rem;
            }

            &--next {
                right: .5rem;
            }

            &:hover,
            &:focus {
                background-color: var(--#{$prefix}primary-bg);
            }
        }
    }

    .bannerItem {
        width: 100%;
        height: auto;
        display: block;
        cursor: pointer;

        &__image.ui.image {
            aspect-ratio: 16 / 9;
            object-fit: cover;
            width: 100%;
            height: auto;
        }
        &:hover, &:focus {
            background-color: var(--#{$prefix}primary-bg);
            img {
                opacity: .66;
            }
        }
    }

    // 768 pixels - 48em
    @media (min-width: map.get($grid-breakpoints, "md")) {
        .slider-wrapper {
            --slide-size: 50%;
        }
    }

    // 992 pixels - 62em
    @media (min-width: map.get($grid-breakpoints, "lg")) {
        .slider-wrapper {
            --slide-size: 33.33%;
            --slide-spacing: 1.5rem;
        }
    }

    // 1280 pixels - 75em
    @media (min-width: map.get($grid-breakpoints, "xl")) {
        .slider-wrapper {
            --slide-size: 25%;
        }
    }

    // 1360 pixels - 75em
    @media (min-width: 85rem) {
        .slideControls {
            .slider__button {
                &--prev {
                    right: calc(100% + 0.5rem);
                    left: auto;
                }

                &--next {
                    left: calc(100% + .5rem);
                    right: auto;
                }
            }
        }
    }
}