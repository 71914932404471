@use '../variables' as *;
@use "sass:map";

// **********************************************************
// PRINT
.printBtn {
    &, &.ui.button {
        background-color: transparent;
        border-color: transparent;
        color: var(--#{$prefix}body-color);
        &:hover, &:focus {
            background-color: var(--#{$prefix}light);
        }
    }
}

// **********************************************************
// DOCUMENT ACTIONS
.documentActions {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.share-wrapper {
    display: flex;
    align-items: center;
    &__title {
        font-size: $h5-font-size;
        font-weight: $font-weight-normal;
        margin: 0 auto 0 0;
    }
    .socialShare__link {
        width: 2.5rem;
        height: 2.5rem;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        font-size: $h5-font-size;
        color: var(--#{$prefix}primary-txt);
    }
}