@use './variables' as *;

body.highContrast {
    $contrastDark: #180059;
    $contrastLight: #FFFFFF;
    
    --#{$prefix}contrastDark:       #{$contrastDark};
    --#{$prefix}contrastLight:      #{$contrastLight};
    --#{$prefix}contrastDark-rgb:   24, 0, 89;
    --#{$prefix}contrastLight-rgb:  255, 255, 255;
    --#{$prefix}body-color-rgb:     255, 255, 255;
    --#{$prefix}primary:            #{$contrastLight};
    --#{$prefix}primary-bg:         #{$contrastDark};
    --#{$prefix}primary-txt:        #{$contrastLight};
    --#{$prefix}primary-rgb:        --#{$prefix}contrastLight-rgb; 
    --#{$prefix}tertiary:           #{$contrastLight};   
    --#{$prefix}secondary:          #{$contrastDark};   
    --#{$prefix}black:              #{$contrastDark};
    --#{$prefix}black-rgb:          --#{$prefix}contrastLight-rgb;
	--#{$prefix}white:              #{$contrastDark};
	--#{$prefix}white-bg:           #{$contrastDark};
	--#{$prefix}white-txt:          #{$contrastLight};
	--#{$prefix}white-rgb:          var(--#{$prefix}contrastDark-rgb);
    --#{$prefix}light:              #{$contrastDark};
    --#{$prefix}dark-primary:       #{$contrastDark};
    --#{$prefix}dark:               #{$contrastDark};
    --#{$prefix}dark-rgb:           var(--#{$prefix}contrastLight-rgb);
    --#{$prefix}gray-500:           #{$contrastLight};
    --#{$prefix}gray-200:           #{$contrastDark};
    --#{$prefix}gray-600:           #{$contrastDark};
    --#{$prefix}grey:               #{$contrastDark};

    --#{$prefix}facebook:           #{$contrastDark};
    --#{$prefix}twitter:            #{$contrastDark};
    --#{$prefix}whatsapp:           #{$contrastDark};
    --#{$prefix}linkedin:           #{$contrastDark};
    --#{$prefix}youtube:            #{$contrastDark};

    --#{$prefix}text-opacity:       1 !important; 

    --#{$prefix}type-bg:            #{$contrastDark} !important;
    --#{$prefix}badge-color:        #{$contrastLight} !important;

    --#{$prefix}slideButtonBg:		transparent;
	--#{$prefix}slideButtonColor:  	var(--#{$prefix}--#{$prefix}contrastDark);
	--#{$prefix}slideButtonBg-hover:transparent;
	--#{$prefix}slideButtonColor-hover:var(--#{$prefix}contrastDark);   

    // minisites
    --#{$prefix}minisiteColor-red:        24;
    --#{$prefix}minisiteColor-green:      0;
    --#{$prefix}minisiteColor-blue:       89;
    --#{$prefix}contrast-color:           255;

   // **********************************************************
    // BODY
    --#{$prefix}body-bg: #{$contrastDark};
    --#{$prefix}body-color: #{$contrastLight};

    
    // **********************************************************
    // HEADER
    --#{$prefix}bgMenu: #{$contrastDark};
    
    .portalHeader {
        &__info__weather__img {
            filter: none;
        }
    }

    // **********************************************************
    // LINKS
    --#{$prefix}link-color: #{$contrastLight};
    --#{$prefix}link-hover-color: #{$contrastLight};

    * {
        background-image: none !important;
    }
    @each $color, $value in $theme-colors {
        .bg-#{$color} {
            background-color: var(--#{$prefix}contrastDark) !important;
        }
        .text-bg-#{$color}, .btn-#{$color}, .btn-outline-#{$color} {
            background-color: var(--#{$prefix}contrastDark) !important;
            color:var(--#{$prefix}contrastLight) !important;
            border-color: var(--#{$prefix}contrastLight) !important;
        }
    }
    .bg-white {
        background-color: var(--#{$prefix}contrastDark) !important; 
    }
    .text-bg-white, .btn-white, .btn-outline-white {
        background-color: var(--#{$prefix}contrastDark) !important;
        color:var(--#{$prefix}contrastLight) !important;
        border-color: var(--#{$prefix}contrastLight) !important;
    }
    .border-white {
        --#{$prefix}white-rgb: var(--#{$prefix}contrastLight-rgb);
    }
    .opacity-50 { opacity: 1 !important;}
     
    // **********************************************************
    // BUTTONS
    .ui.button {
        background-color: var(--#{$prefix}contrastDark) !important;
        color: var(--#{$prefix}contrastLight) !important;
        border: 1px solid var(--#{$prefix}contrastLight);
        &:hover, &:focus {
            color: var(--#{$prefix}contrastDark) !important;
            background-color: var(--#{$prefix}contrastLight) !important;
        }
    }
    .ui.basic.buttons .button, 
    .ui.basic.button {
        color: $contrastLight !important;
        &:hover, &:focus {
            color: $contrastDark !important;
        }
    }
    
    .portalHeader__actions .ui.basic.button.contrastToggle {
        background-color: var(--#{$prefix}contrastLight) !important;
        color: var(--#{$prefix}contrastDark) !important;
    }

    .ui.primary.button {
        border: 1px solid $contrastLight;
    }
    
    .portalHeader__actions {
        .contrastToggle, 
        .contrastToggle.ui.basic.button, 
        .fontSwitch, 
        .fontSwitch.ui.basic.button, 
        .printBtn, 
        .printBtn.ui.basic.button {
            &:hover, &:focus {
                color: var(--#{$prefix}contrastDark) !important;
            }
        }
    }

    // **********************************************************
    // HOME
    .slideItem {
        --#{$prefix}slideItem-color: #{$contrastLight};
        --#{$prefix}slideItem-bg:  #{$contrastDark};
        --#{$prefix}slideItem-bg-rgb: var(--#{$prefix}contrastDark-rgb);
        &__title, &__desc {
            background-color: var(--#{$prefix}slideItem-bg);
        }
    }
    .usefulInfo__wrapper {
        background-color: transparent;
        backdrop-filter: none;
    }
    
    // **********************************************************
    // SLIDESHOW 
    .slideItem {
        --#{$prefix}white: #{$contrastLight};
	    --#{$prefix}white-rgb: var(--#{$prefix}contrastLight-rgb);
    } 
    .slider__dot {
        --#{$prefix}dotColor: var(--#{$prefix}contrastLight); 
    }

    
    // **********************************************************
    // NEWS 
    .searchList__title, 
    .searchList__title.ui.header, 
    .searchList.form.ui__title, 
    .searchList.form.ui__title.ui.header {
        background-color: var(--#{$prefix}contrastDark);
    }
    .ui.cards > .card .meta, 
    .ui.card .meta {
        color: var(--#{$prefix}contrastLight);
    }
    .searchList.form.ui .ui.input input[type="text"], 
    .searchList.form.ui .ui.selection.dropdown, 
    .searchList .ui.input input[type="text"], 
    .searchList .ui.selection.dropdown {
        border: 1px solid var(--#{$prefix}contrastLight);
    }
    
    // **********************************************************
    // EVENTS
     

    // **********************************************************
    // SEARCH
    .modalSearch .modal-dialog {
        background-color: var(--#{$prefix}contrastDark);
        background-image: none;
    }
    .basicSearch__input, .basicSearch__input.ui.input {
        border-color: var(--#{$prefix}contrastLight);
    }

    // **********************************************************
    // FORMS
    .form-control{
        background-color: var(--#{$prefix}contrastDark);
        &::placeholder {
            color: var(--#{$prefix}contrastLight);
        }
    }
    .form-select {
        background-color: var(--#{$prefix}contrastDark);
        color: var(--#{$prefix}contrastLight);
    }

    // **********************************************************
    // MODALS
    .btn-close {
        color: var(--#{$prefix}contrastLight);
        opacity: 1; 
        background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;           
    }

    // **********************************************************
    // INNER HEADER
    .ui.breadcrumbs {
        .ui.breadcrumb a.section,
        .ui.breadcrumb a {
            color: var(--#{$prefix}contrastLight);
         }
    }

    // **********************************************************
    // FOOTER
    .portalFooter {
        --sa-link-color: var(--#{$prefix}contrastLight);
        --sa-link-hover-color: var(--#{$prefix}contrastLight);
		.text-white {
			color: var(--#{$prefix}contrastLight) !important;
		}
        .footerXXSS__link {
            color: var(--#{$prefix}contrastLight);
        }
    }
    .xxssBlock__link {
        --#{$prefix}white-bg: #{$contrastLight};
    }
    .footerLogo__img {
        filter:brightness(100);
    }
    .bannersFooter .slideControls .slider__button {
        background-color: var(--#{$prefix}contrastDark);
        border: 2px solid var(--mdp-contrastLight);
    }

    // **********************************************************
    // LIST
    

    // **********************************************************
    // PAGE
    .relatedContent {
        --#{$prefix}related-bg-color: #{$contrastDark};
        --#{$prefix}related-text-color: #{$contrastLight};
        --#{$prefix}related-link-color: #{$contrastLight};
        --#{$prefix}related-icon-color: #{$contrastLight};
        --#{$prefix}link-color: #{$contrastLight};
        --#{$prefix}link-hover-color: #{$contrastLight}; 
    }
    .bg-working {
        background-color: var(--#{$prefix}contrastDark);
    }
    .instrumentMenu {
        --#{$prefix}white: $contrastLight;
    }

      
    // **********************************************************
    // LABELS
    .ui.label {
        background-color: $contrastLight;
        color: $contrastDark;
    }

    // **********************************************************
    // VOLTO
    .transBox__title, .transBox__title.ui.header,
    .transBox.ui.segment, .transBox {
        color: var(--#{$prefix}contrastLight);
    }

    // **********************************************************
    // MINISITE
    .headMinisite{
        &__title {
            background-color: transparent;
            &__img {
                display: none;
            }
        }
    }

    // **********************************************************
    // BLOG UNFOLDED
    .blogUnfoldedItem__img {
        display: none;
    }
}