@use '../variables' as *;
@use "sass:map";
@use '../apps/mixins/utilities';

// **********************************************************
// FRONTPAGE
.eventsHome {

	&__wrapper {
		background-color: var(--#{$prefix}light);
		padding-top: 2rem;
	}
	&__title {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-bottom: 1.5rem;
		.ui.header {
			margin-bottom: 0;
			text-align: center;
			justify-content: center;
		}
	}
	&__rss {
		display: inline-flex;
		justify-content: center;
		align-items: center;
		margin-left: 1rem;
		font-size: 1rem;
		width: 2.5rem;
		height: 2.5rem;
		border-radius: 50%;
		color: var(--#{$prefix}white-txt);
		background-color: var(--#{$prefix}primary-bg);
		&:hover, &:focus {
			background-color: var(--#{$prefix}body-color);
			color: var(--#{$prefix}white-txt);
		}
	}
}

// **********************************************************
// CATEGORIES
.eventsHome__categories {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	gap: .25rem;
	margin-bottom: 1.5rem;
	&__item {
		&, &.ui.button {
			border-radius: var(--#{$prefix}border-radius-sm);
			font-size: $font-size-base;
			font-weight: $font-weight-bold;
			min-width: 10.5rem;
			max-width: 14rem;
			margin: 0;
			flex: 0 0 auto;
			color: var(--#{$prefix}black);
			background-color: var(--#{$prefix}white-bg);
			min-height: 3rem;
			position: relative;
			&.active {
				background-color: var(--#{$prefix}black);
				color: var(--#{$prefix}white-txt);
			}
			&:hover, &:focus {
				background-color: var(--#{$prefix}primary-bg);
				color: var(--#{$prefix}white-txt);
			}
		}
		&__icon {
			position: absolute;
			right: 0.5rem;
			top: 0.5rem;
			z-index: $zindex-flat + 1;
		}
	}
}

// **********************************************************
// EVENT ITEM
.eventsItem {
	.column > &, .column > &.ui.card {
		width: 100%;
		margin: 0;
		border-radius: var(--#{$prefix}border-radius-lg);
		height: 100%;
		background-color: var(--#{$prefix}white-bg);
	}	
	&__img {
		border-radius: var(--#{$prefix}border-radius-lg) var(--#{$prefix}border-radius-lg) 0 0;
		width: 100%;
		height: auto;
		aspect-ratio: 16 / 9;
		object-fit: cover;
	}
	&__text{
		flex-grow: 1;
		display: flex;
		flex-direction: column;
	}	
	&__title {
		&, &.header {
            text-align: left !important;
			text-wrap: balance;
        }
	}
	&__link {
		color: var(--#{$prefix}body-color);
		text-decoration: none;
		&:hover, &:focus {
            color: var(--#{$prefix}primary-txt);
        }
	}
	&__icon {
		color: var(--#{$prefix}primary-txt);
		font-size: 1.25rem;
		margin-right: .5rem !important;
		width: 1.5rem;
		flex-shrink: 0;
	}
	&__desc {
		&, &.description {
			margin-bottom: 1rem !important;
			font-size: $font-size-base;
		}
	}
	hr {
		border-color: $gray-600;
		margin-bottom: 1rem !important;
		width: 100%;
		margin-top: auto;
	}
	&__location,
	&__date {
		display: flex;
	}
	&__meta {
		&, .ui.card &.meta {
			display: flex;
			font-size: $font-size-base;
			color: var(--#{$prefix}body-color);
		}
		&__list {
			display: flex;
			margin:0;
			flex-wrap: wrap;
		}
	}
}

// **********************************************************
// SUMMARY
.event-view {
	&__wrapper {
		&, &.ui.grid {
			margin-bottom: 5rem;
		}
	}
	&__summary {
		&, &.ui.segment {
			background-color: var(--#{$prefix}gray-200);
			border:none;
			box-shadow: none;
			padding-top: 1.5rem;
		}
		&__term {
			&, &.ui.dividing.header {
				font-size: $font-size-base;
				border-bottom: none;
				text-transform: none;
				&::first-letter {
					text-transform: uppercase;
				}
			}
		}
		dd {
			margin-left: 0;
		}
	}
}

// **********************************************************
// MAP
.leaflet-container {
	@supports (aspect-ratio: 1/1){
		height: auto !important;
		aspect-ratio: 1 / 1;
		width: 100%;
		// 992 pixels - 62em
		@media (min-width: map.get($grid-breakpoints, "lg")) {
			aspect-ratio: 16/9;
		}
	}
}

// **********************************************************
// CALENDAR LIST
.calendarList {
    margin-bottom: 2.4rem;
    &__month {
        display: block;
        text-transform: uppercase;
        font-weight: $font-weight-light;
        line-height: 1;
        white-space: nowrap;
    }
    &__days {
        flex-grow: 1;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-bottom: 0;
        position: relative;
        & > .calendarList__dropdown {
            flex: 0 0 auto;
            margin: 1px;
            width: 2.5rem; 
            position: static;
            &:nth-last-child(-n+5) .dropdown-menu {
                left: auto;
                right: 0;    
            }
			&:hover, &:focus-within {
				.menu.transition {
					display: block !important;
					visibility: visible !important;
				}
			}
        }
    }
    &__link {
		&, &.ui.dropdown {
			transition: background-color $transition-fast;
			background-color: var(--#{$prefix}primary);
			color: var(--#{$prefix}white);
			display: inline-flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			height: 2.5rem;
			text-align: center;
			padding: .125rem;
			font-size: $font-size-sm;
			font-weight: $font-weight-semibold;
			text-transform: capitalize;        
			text-decoration: none;        
			line-height:$headings-line-height;
			width: 100%;
			border: none;
			&.isPast {
				background-color: var(--#{$prefix}gray-600);
			}
			&.isToday {
				background-color: var(--#{$prefix}black);
			}
		}
		abbr {
			text-decoration: none;
		}
        &:hover, &:focus {
            text-decoration: none;
            background-color: var(--#{$prefix}white) !important;
            color: var(--#{$prefix}text-color) !important;
        }
    }
    .events-wrapper {
        padding: 0.5rem;
    }
	.ui.dropdown .menu > .item {
		padding: 0 !important;
		& > * {
			white-space: normal;
		}
		& + .item {
			border-top: 1px solid var(--#{$prefix}gray-200);
		}
	}
	.calendarItem { 
		&, &.ui.card {
			box-shadow: none;
			border: none;
			flex-direction: row;
			align-items: flex-start;
			.content {
				font-size: $font-size-sm;
			}
			&.fluid {
				max-width: 18rem;
			}
		}
		&__title {
			&, &.header {
				font-size: $h6-font-size !important;
			}
		}
		&__link {
			text-decoration: none;
			color: var(--#{$prefix}body-color);
		}
		&__date, &__meta {
			display: flex;
		}
		&__img {
			width: 100%;
			height: auto;
			&__wrapper {
				width: 25%;
				flex-shrink: 0;
				margin: 1rem;
				margin-right: 0;
			}
		}
		&__text {
			&, &.content {
				border-top: none !important;
			}
		}
	}

    // 1200 pixels - 75em
    @media (min-width: map.get($grid-breakpoints, "lg")) {
       
        &__days {
            flex-wrap: nowrap;
            & > .dropdown {
                position: relative;
                width: 2rem;
                margin: 0;
                flex: 1 1 0;
                & + .dropdown {
                    margin-left: 0.1rem;
                }
            }
        }
    }
    // 1600 pixels - 100em
    @media (min-width: map.get($grid-breakpoints, "xl")) {
        &__link {
            height: 3.5rem;
        }
    }
}

// #################################
// CALENDAR LIST QUERY
.eventItem.eventItem--query{
    min-width: 28.0rem;
    .eventItem {
        &__image {
            flex: 0 0 33%;
            margin-right: .8rem;
            margin-top: 0;
            img {
                margin-left: 0;
            }
        }
        &__wrapper {
            display: block;
            flex-grow: 1;
            &::before {
                content: none;
            }
        }
        &__subject {
            border-bottom: none;
            border-top: .1rem solid var(--#{$prefix}subject-color);
            padding-top: 0.4rem;
            display: flex;
            height: auto;
        }
        &__date {
            display: block;
            margin-bottom: 0.4rem;
            margin-top: 0;
            &__text {
                display: inline;
            }
            &__day {
                display: inline;
                font-size: var(--#{$prefix}size-h6);
                font-weight: $font-weight-semibold;
            }
        }
        &__title {
            font-size: var(--#{$prefix}size-h6);
        }
        &__link {
            &:hover, &:focus {
                color: var(--#{$prefix}primary);
            }
        }
        &__readmore {
            margin-left: auto;
        }
    }
    &:hover, &:focus {
        color: var(--#{$prefix}primary);
        .eventItem__subject, .eventItem__readmore {
            color: var(--#{$prefix}primary);
        }
        .eventItem__date {
            margin-left: 0;
        }
    }
}