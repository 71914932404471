@use '../variables' as v;
//
// Stretched link
//

.stretched-link {
	&::after {
	  position: absolute;
	  top: 0;
	  right: 0;
	  bottom: 0;
	  left: 0;
	  z-index: v.$zindex-flat;
	  content: "";
	}
  }
  