@use '../variables' as *;
@use '../apps/mixins/utilities';
@use '../apps//visually-hidden' as vh;
@use "sass:map";

// **********************************************************
// LAYOUT
.portalHeader {

	&,
	&.ui.basic.segment {
		margin-bottom: 0;
		background-color: var(--#{$prefix}primary-bg);
		color: var(--#{$prefix}white-txt);
		padding-left: 0;
		padding-right: 0;
		position: relative !important;
	}

	.ui.container {
		display: flex;
		align-items: center;
	}

	&__navToggle {
		margin-left: auto;
	}

	&__wrapper {
		display: none;
		position: absolute;
		right: 0;
		top: 100%;
		background-color: var(--#{$prefix}light);
		box-shadow: 0 5px 5px rgba(var(--#{$prefix}dark-rgb), .33);
		z-index: $zindex-popover;

		&.expand {
			display: block;
		}
	}

	// 992 pixels - 62em
	@media (min-width: map.get($grid-breakpoints, "lg")) {
		&,
		&.ui.basic.segment {
			padding-top: 0;
			padding-bottom: .5rem;
		}
		&__wrapper {
			background-color: transparent;
			position: relative;
			right: auto;
			top: auto;
			box-shadow: none;
			flex-grow: 1;
			&, &.expand {
				display: grid;
				grid-template-columns: auto 1fr auto auto;
				grid-template-rows: auto auto;
				row-gap: .5rem;
			}
		}
		&__info {
			grid-column: 1 / 2;
			grid-row: 1 /2;
		}
		&__actions {
			grid-column: 3 / 5;
			grid-row: 1 / 2;
		}
		.navigation {
			grid-column: 1 / 4;
			grid-row: 2 / 3;
		}
		.searchToggle {
			grid-column: 4 / 5;
			grid-row: 2 / 3;
		}
	}
}

// **********************************************************
// TIME AND WEATHER
.portalHeader__info {
	padding: 2rem 1.5rem;
	color: var(--#{$prefix}body-color);
	font-size: $font-size-base;
	line-height: $line-height-sm;
	display: flex;
	align-items: center;

	&__weather {
		display: flex;
		align-items: center;
		margin-left: 1rem;
		font-weight: $font-weight-bold;
		font-size: $font-size-base;
		text-decoration: none;
		color: var(--#{$prefix}body-color); 
		flex-shrink: 0;

		&__img {
			filter: invert(100%);
			height: 2rem;
			width: auto;
			margin-left: 0.5rem;
		}
		&:hover, &:focus {
			color: var(--#{$prefix}white-txt);
			text-decoration: underline;
		}
	}

	// 992 pixels - 62em
	@media (min-width: map.get($grid-breakpoints, "lg")) {
		color: var(--#{$prefix}white-txt);
		justify-content: flex-start;
		padding:  0rem 1.5rem;
		font-size: $font-size-sm;
		&__weather {
			color: var(--#{$prefix}white-txt);
			&__img {
				filter: none;
			}
		}
	}
}

// **********************************************************
// LOGO
.portalLogo {
	&__link {
		flex-shrink: 0;
	}
	&__img {
		width: 9rem;
		height: auto;
	}
}

// **********************************************************
// SEARCH
.searchToggle {
	@include utilities.clean-button;
	color: var(--#{$prefix}white-txt);
	font-size: 1.25rem;
	cursor: pointer;
	&:hover, &:focus {
		background-color: transparent;
		color: var(--#{$prefix}body-color);
	}
	display: none;
	.portalHeader__actions & {
		display: inline-flex;
		color: var(--#{$prefix}body-color);
	}
	// 992 pixels - 62em
	@media (min-width: map.get($grid-breakpoints, "lg")) {
		display: inline-flex;
		.portalHeader__actions & {
			display: none;
		}
	}
}

// **********************************************************
// SHARE AND A11Y
.portalHeader__actions {
	padding: 1rem;
	display: flex;
	align-items: center;
	justify-content: center;

	.dropdown.ui {
		height: 2.5rem;
		width: 2.5rem;
		color: var(--#{$prefix}body-color);
		font-size: $font-size-base;
		justify-content: center;
		align-items: center;
		.menu {
			box-shadow: none;
			border: none;
			&.visible {
				display: flex !important;
			}
			.item {
				padding: 0 !important;
				.ui.button {
					@include utilities.clean-button;
					margin: 0;
					padding: 0 !important;
					&.btnFacebook {
						background-color: var(--#{$prefix}facebook);
						color: var(--#{$prefix}white-txt);
					}
					&.btnTwitter {
						background-color: var(--#{$prefix}twitter);
						color: var(--#{$prefix}white-txt);
					}
					&.btnWhatsapp {
						background-color: var(--#{$prefix}whatsapp);
						color: var(--#{$prefix}white-txt);
					}
					&.btnLinkedin {
						background-color: var(--#{$prefix}linkedin);
						color: var(--#{$prefix}white-txt);
					}
					
				}
			}
		}
	}

	.contrastToggle,
	.fontSwitch,
	.printBtn,
	.btnSearchToggle {
		&,
		&.ui.basic.button {
			@include utilities.clean-button;
			color: var(--#{$prefix}body-color) !important;
			font-size: $h5-font-size;
			&:hover, &:focus {
				color: var(--#{$prefix}primary-txt) !important;
				background-color: transparent;
			}
		}
	}

	// 992 pixels - 62em
	@media (min-width: map.get($grid-breakpoints, "lg")) {
		font-size: $font-size-sm;
		justify-content: flex-end;
		padding: 0;
		.dropdown.ui {
			color: var(--#{$prefix}white-txt);
		}
		.contrastToggle,
		.fontSwitch,
		.printBtn {
			&,
			&.ui.basic.button {
				color: var(--#{$prefix}white-txt) !important;
				&:hover, &:focus {
					color: var(--#{$prefix}body-color) !important;
					background-color: transparent;
				}
			}
		}
	}
}


// **********************************************************
// TITLE
 h1.documentFirstHeading {
	border-bottom: none;
	margin-bottom: 1.5rem;
	font-size: $h4-font-size;
	&::before {
		content: none;
	}
	body.frontpage & {
		@include vh.visually-hidden();
	}
}

// DESCRIPTION
.documentDescription {
	color: var(--#{$prefix}body-color);
	font-size: $h5-font-size;
	max-width: 80ch;
	margin-bottom: 1.5rem !important;
}