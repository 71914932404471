@use 'utilities';
@use "sass:map";
@use "sass:meta";
@use "sass:string";

// Print utilities
@media print {
	@each $key, $utility in utilities.$utilities {
	  // The utility can be disabled with `false`, thus check if the utility is a map first
	  // Then check if the utility needs print styles
	  @if meta.type-of($utility) == "map" and map.get($utility, print) == true {
		@include utilities.generate-utility($utility, "-print");
	  }
	}
  }
  