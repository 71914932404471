@use '../variables' as *;

@media print {

// **********************************************************
// CSS VARIABLES
body {
    --#{$prefix}body-color: black;
    --#{$prefix}white-bg: white;
    --#{$prefix}white-txt: black;
    --#{$prefix}primary-bg: white;
    font-size: 12pt;
}

// **********************************************************
// ANNOUNCEMENTS
.announcements {
    .slider__container {
        flex-direction: column;
    }
}
// **********************************************************
// HEADER
.portalLogo__img {
    filter: invert(100%);
}
.portalHeader{
    &__actions .dropdown.ui,
    &__info, &__info__weather {
        color: black;
    }
    &__info__weather__img {
        filter: invert(100%);
    }
}

// **********************************************************
// SLIDESHOW
#featuredSection .slider__container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
}

// **********************************************************
// BLOCK NEWS 
.newsHome {
    .ui.grid {
        display: flex;
        flex-wrap: nowrap;
        .column {
            flex: 1 1 0;
        }
    }
    .newsItem--featured {
        &, &.ui.card {
            max-width: 66%;
            margin-left: auto;
            margin-right: auto;
        }
    }
}
.ui.card {
    break-inside: avoid;
}

// **********************************************************
// BLOCK AGENDA
.eventsHome {
    .ui.grid {
        display: flex;
        flex-wrap: nowrap;
        .column {
            flex: 1 1 0;
        }
    }
}
.eventsListing {
    .ui.grid > .row.twelve.column {
        & > .column.wide {
            width: 32% !important;
        }
    }
}

// **********************************************************
// BANNERS
.banners-slider {
    .slider__container {
        display: flex;
        flex-wrap: wrap;
        row-gap: var(--slide-spacing);
    }
    .slider__slide {
        width: 25%;
        flex: 0 1 auto;
    }
}

// **********************************************************
// COOKIES
button.gdpr-privacy-show-banner {
    display: none !important;
}

// **********************************************************
// BLOG 
.blogView__page {
    .ui.grid {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
    }
}

// **********************************************************
// BLOG UNFOLDED
.blogUnfoldedItem__children.ui.grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr; 
    .blogUnfoldedItem__img {
        display: none;
    }
}

// **********************************************************
// SEARCH

}
