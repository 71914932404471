@use '../variables' as *;
@use '../apps/utilities' as *;
@use "sass:map";


// **********************************************************
// HEADER
.headMinisite {
    display: block;
    &__title {
        font-weight: $font-weight-bold;
        font-size: $h1-font-size;
        text-transform: uppercase;
        color: var(--#{$prefix}white-txt);
        border-radius: var(--#{$prefix}border-radius);
        background-color: rgba(var(--#{$prefix}dark-rgb), .70);
        padding: 0 1.25rem;
        position: relative;
        z-index: $zindex-flat + 1;
        line-height: $headings-line-height;

        &__wrapper {
            position: relative;
            display: flex;
            flex-direction: column;
            text-align: center;
            padding: 1rem;
            justify-content: center;
            align-items: center;
            min-height: 16rem;
        }

        &__img {
            z-index: $zindex-flat;
            position: absolute;
            left: 0;
            width: 100%;
            top: 0;
            height: 100%;
            object-fit: cover;
            object-position: center center;
        }
    }

    body.contenttype-minisite & {
        display: block;
    }

    & + .content-area {
        &, &.ui.segment.basic {
            // padding-top: 2.5rem;
        }
    }
}

// **********************************************************
// NAVEGACIO
.minisiteNav {
    --#{$prefix}minisiteColor: rgb(var(--#{$prefix}minisiteColor-red), var(--#{$prefix}minisiteColor-green), var(--#{$prefix}minisiteColor-blue));
    --#{$prefix}contrast-color: calc(((((var(--#{$prefix}minisiteColor-red) * 299) + (var(--#{$prefix}minisiteColor-green) * 587) + (var(--#{$prefix}minisiteColor-blue) * 114)) / 1000) - 128) * -1000);
    --#{$prefix}minisiteContrast: rgb(var(--#{$prefix}contrast-color, 255), var(--#{$prefix}contrast-color, 255), var(--#{$prefix}contrast-color, 255));

    padding: 1rem 0;
    background-color: var(--#{$prefix}minisiteColor, #38396D);
    color: var(--#{$prefix}minisiteContrast);

    &__list {
        display: none;
        margin-bottom: 0;
        flex-direction: column;

        &.expand {
            display: flex;
        }
    }

    &__item {
        &+& {
            border-top: 1px solid currentColor;
        }
    }

    &__link {
        transition: all .25s;
        text-decoration: none;
        font-size: $h6-font-size;
        font-weight: $font-weight-bold;
        color: var(--#{$prefix}minisiteContrast);
        display: block;
        padding: 1rem .5rem;
        text-align: left;

        &:hover,
        &:focus {
            text-decoration: underline;
            text-underline-offset: .33em;
        }
    }

    // 992 pixels - 62em
    @media (min-width: map.get($grid-breakpoints, "lg")) {
        &__list {
            display: flex;
            flex-direction: row;
            margin-top: 0;
        }

        &__item {
            flex: 1 0 auto;

            &+& {
                border-top: none;
                border-left: 1px solid currentColor;
            }
        }

        &__link {
            text-align: center;
            padding: .25rem .5rem;
        }
    }
}

// **********************************************************
// AGENDA
body.contenttype-minisite {
    .block.listing.events {
        @include mixin-full-width;
        background-color: var(--#{$prefix}light);
        padding-top: 0.5rem;
        padding-bottom: 2.5rem;
        margin-bottom: 0;

        .listingListWrapper {
            margin-left: auto;
            margin-right: auto;

            @each $breakpoint in map.keys($container-max-widths) {
                @media (min-width: map.get($grid-breakpoints, $breakpoint)) {
                    max-width: #{map.get($container-max-widths, $breakpoint)};
                }
            }
        }
    }
}

// **********************************************************
// EQUIMPMENTS
.equipmentsMapListing {
    &__list {
        position: relative;

        &__item {
            padding-bottom: 1rem;
            margin-bottom: 1rem;
            border-bottom: 1px solid var(--#{$prefix}gray-500);

            &__title {
                font-weight: $font-weight-bold;
                color: var(--#{$prefix}primary-txt);
                font-size: $h6-font-size;
            }
            a {
                text-decoration: none;
                &:hover, &:focus {
                    color: var(--#{$prefix}body-color);
                    text-decoration: underline;
                    text-underline-offset: .25em;
                    text-decoration-thickness: 2px;
                    .equipmentsMapListing__list__item__title {
                        color: var(--#{$prefix}body-color);
                    }
                }
            }
            &:last-child {
                border-bottom: none;
            }
        }
    }

    // 768 pixels - 48em
    @media (min-width: map.get($grid-breakpoints, "md")) {
        body.has-toolbar:not(.has-sidebar):not(.has-sidebar-collapsed) &.ui.grid {
            padding-left: 3.5rem;
        }
        &__list {
            position: absolute;
            top: 0;
            left: 0;
            width: calc(100% + 2rem);
            height: 100%;
            overflow: auto;
            margin-top: 0;
            padding: 1rem 2rem 1rem 0;
        }

        &__map.column {
            padding-top: 0 !important;
        }
    }
    
}

// **********************************************************
// AMAGAR BREADCRUMBS A LA PORTADA DEL MINISITE
body.minisiteTree  {
    .breadcrumbs{
        &, &.ui.secondary.segment, .breadcrumbs__bg {
           display: none;
        }
    }
    .headMinisite .breadcrumbs{
        &, &.ui.secondary.segment {
           display: block;
           min-height: 0;
        }
    }
}