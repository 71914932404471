@use '../variables' as *;
@use "sass:map";

// **********************************************************
.customer-satisfaction {
	&.ui.segment, & {
		padding-top: 2rem;
		padding-bottom: 2rem;
		background-color: var(--#{$prefix}dark);
		color: var(--#{$prefix}white-txt);
	}
	.ui.grid > .row {
		max-width: 60rem;
		margin-left: auto;
		margin-right: auto;
	}
	&__title {
		color: var(--#{$prefix}white-txt);
		margin-bottom: 0;
	}
	&__desc {
		font-size: calc(#{$font-size-base} * var(--#{$prefix}fs-scale));
		font-family: $headings-font-family;
		color: var(--#{$prefix}white-txt);
		margin-bottom: 2rem !important;
	}
	.buttons {
		flex-wrap: wrap;	
		justify-content: flex-end;	
	}
	& .buttons &__btn.ui.button {
		min-height: 3rem; 
		max-width: 100%;
		display: inline-flex;
		justify-content: center;
		align-items: center;
		padding-left: 1.5em;
		padding-right: 1.5em;
	}
	.content.customer-satisfaction__btn__icon {
		margin-left: 1rem;
		&-no {
			translate: 0 .25em;
		}
	}
	.ui.button {
		transition: $transition-base;
		background-color: transparent;
		color: var(--#{$prefix}white);
		font-size: calc(#{$font-size-sm} * var(--#{$prefix}fs-scale));
		font-family: $headings-font-family;
		margin: .5rem 1.25rem;
		border: 1px solid var(--#{$prefix}white-bg);
		&:hover, &:focus {
			background-color: var(--#{$prefix}primary-bg);
			color: var(--#{$prefix}white-txt);
			border-color: var(--#{$prefix}primary-bg);
			.visible.content {
				opacity: 1 !important;
			}
		}
	}
	.ui.form textarea {
		background-color: var(--#{$prefix}dark);
		color: var(--#{$prefix}white-txt);
		border: 1px solid var(--#{$prefix}white-txt);
		padding: 1em;
		&::placeholder {
			opacity: .8;
			color: var(--#{$prefix}white-bg);
		}
	}

	// 768 pixels - 48em
	@media (min-width: map.get($grid-breakpoints, "md")) {
		&__desc {
			margin-bottom: 0 !important;
		}		
	}
	// 992 pixels - 62em
	@media (min-width: map.get($grid-breakpoints, "lg")) {		
		.buttons {
			display: flex;
			flex-wrap: nowrap;
			justify-content: flex-end;
		}
		.ui.button {
			margin-bottom: 0;
			margin-top: 0;
		}
	}
}