// Clean buttons
@mixin clean-button {
    border-radius: 0;
    border: none;
    background-color: transparent;
    text-shadow: none;
    box-shadow: none;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    min-width: 2.5rem;
    min-height: 2.5rem;
}