@use '../../variables' as *;
@use '../../apps/utilities' as *;
@use "sass:map";

// **********************************************************
// LAYOUT
.block.teaser {
    a {
        text-decoration: none;
        display: block;

        &:hover,
        &:focus {
            h2 {
                color: var(--#{$prefix}primary);
                text-decoration: underline;
            }
        }
    }

    &.has--align--center .teaser-item {
        flex-direction: column;
    }
    .teaser-item {     
        color: var(--#{$prefix}body-color);
    }
}
.styled-teaser {
    &.styled.right,
    &.styled.left {
        float: none;
    }
    &.styled.right .teaser-item {
        flex-direction: row-reverse;
        .image-wrapper {
            margin-right: 0;
            margin-left: 1.5rem;
        }
        .content {
            text-align: right;
            p {
                text-align: right;
            }
        }
    }
    &.styled.center .teaser-item {
        flex-direction: column; 
    }
}

// **********************************************************
// EDIT VIEW
.block.teaser {
    &::after {
        content: '';
        display: block;
        clear: both;
    }
}

// **********************************************************
// MINISITE
.minisiteStyle {
    @include mixin-full-width;
    background-color: var(--#{$prefix}light);

    &.styled.left,
    &.styled.right {
        float: none;
    }

    .teaser-item {
        color: var(--#{$prefix}body-color);
        flex-direction: column;

        &.default {
            .image-wrapper {
                margin-right: 0;
                margin-bottom: 1.5rem;

                img {
                    display: block;
                    object-position: center center;
                }
            }

            .content {
                padding: 0 1rem 1rem 1rem;
            }
        }
    }

    // 768 pixels - 48em
    @media (min-width: map.get($grid-breakpoints, "md")) {
        body.has-toolbar:not(.has-sidebar):not(.has-sidebar-collapsed) & {
            padding-left: 2.5rem;
        }

        .teaser-item {
            flex-direction: row;
            min-height: 18rem;

            &.default {
                .image-wrapper {
                    flex: 0 0 50%;
                    margin-bottom: 0;
                    position: relative;

                    img {
                        position: absolute;
                        top: 0;
                        height: 100%;
                        width: 100%;
                        left: 0;
                        min-height: 18rem;
                    }
                }

                .content {
                    flex: 0 0 50%;
                    padding: 1rem;
                    padding-right: calc(50vw - (var(--#{$prefix}current-breakpoint) / 2));
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                }
            }
        }

        &.styled.right {
            .teaser-item {
                flex-direction: row-reverse;

                &.default {
                    .content {
                        text-align: right;
                        padding: 1rem;
                        padding-left: calc(50vw - (var(--#{$prefix}current-breakpoint) / 2));
                    }
                }

                p {
                    text-align: right;
                }
            }
        }

        &.styled.center {
            .teaser-item {
                flex-direction: column;

                &.default {
                    .image-wrapper {
                        flex: 1 0 auto;
                        margin-bottom: 1.5rem;
                        position: relative;

                        img {
                            position: static;
                            top: auto;
                            height: auto;
                            width: 100%;
                            left: 0;
                            min-height: 0;
                        }
                    }

                    .content {
                        text-align: center;
                        padding: 0 calc(50vw - (var(--#{$prefix}current-breakpoint) / 2)) 1.5rem calc(50vw - (var(--#{$prefix}current-breakpoint) / 2));
                    }
                }

                p {
                    text-align: center;
                }
            }
        }
    }

    // 992 pixels - 62em
    @media (min-width: map.get($grid-breakpoints, "lg")) {
        .teaser-item {
            flex-direction: row;

            &.default {
                .content {
                    padding: 1.5rem;
                    padding-right: calc(50vw - (var(--#{$prefix}current-breakpoint) / 2));
                }
            }
        }

        &.styled.right {
            .teaser-item {
                &.default {
                    .content {
                        padding: 1.5rem;
                        padding-left: calc(50vw - (var(--#{$prefix}current-breakpoint) / 2));
                    }
                }
            }
        }
    }
}