@use '../variables' as *;
@use "sass:map";

// **********************************************************
// LAYOUT
body.equipmentslisting {
    .segment.basic.content-area {
        margin-bottom: 0;
        padding-bottom: 0;
    }
}
// **********************************************************
// EQUIPMENT ITEM
.equipmentsItem {
    &__link {
        text-decoration: none;
        color: var(--#{$prefix}body-color);
        &:hover, &:focus {
            text-decoration: underline;
            color: var(--#{$prefix}primary-txt);
        }
    }
    &__meta {
        &, .ui.card &.meta {
            display: flex;
            align-items: flex-start;
        }
        &__list {
            margin: 0;
            padding-left: .5rem;
            display: flex;
            flex-wrap: wrap;
            & > * {
                margin-right: .5rem;
            }
        }
    }
    hr {
        opacity: .33;
    }
}