@use '../variables' as *;

// **********************************************************
// FONT SIZE
.fs-sm {
    font-size: $font-size-sm !important;
}
.fs-lg {
    font-size: $font-size-lg;
}

// **********************************************************
// LISTS

// Unstyled keeps list items block level, just removes default browser padding and list-style
@mixin list-unstyled {
    padding-left: 0;
    list-style: none;
}
  
.list-unstyled {
    @include list-unstyled();
}
  
// Inline turns list items into inline-block
.list-inline {
    @include list-unstyled();
}
.list-inline-item {
    display: inline-block;

    &:not(:last-child) {
        margin-right: $list-inline-padding;
    }
}


// **********************************************************
// FONT FAMILY
.ff-headings { 
    font-family: $font-family-sans-serif !important;
}
.ff-text {
    font-family: $font-family-body !important;
}