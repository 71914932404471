@use '../variables' as *;
@use '../apps/mixins/utilities';
@use "sass:map";

// **********************************************************
// LAYOUT 
.slides {
	position: relative;
}
.listingBoxWrapper {
	&.ui.segment {
		padding-top: 0;
	}
}
body.frontpage .block.listing.slider {
	margin-bottom: 0;
	.listingBoxWrapper {
		padding-bottom: 0;
	}
}

// **********************************************************
// CONTROLS
.slides {
	.slider {
		&__dots {
			position: absolute;
			padding-bottom: .5rem;
			padding-top: 0.5rem;
			bottom: 0;
			left: 0;
			right: 0;
			display: flex;
			justify-content: center;
			align-items: center;
			z-index: 3;
			background: linear-gradient(to bottom, rgba(var(--#{$prefix}dark-rgb), 0.00) 0%, rgba(var(--#{$prefix}dark-rgb), .45) 100%);
		}
		&__dot {
			@include utilities.clean-button;
			cursor: pointer;
			&::after {
				content: '';
				display: block;
				width: .75rem;
				height: .75rem;
				border-radius: 50%;
				background-color: var(--#{$prefix}white-bg);				
			}
			&--selected::after {
				width: 1.25rem;
				height: 1.25rem;
				background-color: var(--#{$prefix}primary-bg);
			}
		}
	}
}

// **********************************************************
// ITEMS
.slideItem {
	margin: 0;
    min-height: 24rem;
    width: 100%;
    position: relative;
	height: 100%; 
    align-items: center;
    &__image {
		position: absolute;
        top: 0;
		height: 100%;
		left: 0;
		width: 100%;
        object-fit: cover;
        z-index: 0;
    }
    &__text {
		padding: 1rem 1rem 3.5rem 1rem;
        position: relative;
        z-index: 1;
        color: var(--#{$prefix}body-color);
        text-align: center;
		height: 100%; 
		display: flex !important;
		flex-direction: column;
		justify-content: center;
		&::before, &::after{
			content: none;
		}
    }
    &__desc {
        font-size: $font-size-base;
        display: block;
        padding: .5rem 1rem;
        background-color: rgba(var(--#{$prefix}white-rgb), .7);
        margin: 0 auto .5rem auto;
        line-height: $line-height-sm;
    }
    &__title {
        font-size: $h3-font-size;
		font-weight: $font-weight-bold;
        display: block;
        background-color: rgba(var(--#{$prefix}white-rgb), .7);
        padding: .5rem 1.5rem;
        margin: 0 auto;
        line-height: $line-height-sm;
		text-wrap: balance;
    }
	&__link {
		color: var(--#{$prefix}body-color);
		text-decoration: none;
		&:hover, &:focus {
			background-color: rgba(var(--#{$prefix}primary-rgb), .88) !important;
			color: var(--#{$prefix}white-txt) !important;
			text-decoration: none;
		}
	}

}
// 992 pixels - 62em
@media (min-width: map.get($grid-breakpoints, "lg")) {
	body.frontpage .slider-wrapper {
		--slide-height: calc(100vh - 6.75rem - 16rem);
	}	
	.slideItem {
		min-height: var(--slide-height);
		&__text {
			padding: 3.5rem;
		}
		&__title {
			font-size: $h1-font-size;
		}
	}
}


// **********************************************************
// EMBLA
.slider {
	&-wrapper {
		--slide-spacing: 1rem;
		--slide-size: 100%;
		--slide-height: 24rem; 
	}
	&__viewport {
	  overflow: hidden;
	}
	&__container {
	  backface-visibility: hidden;
	  display: flex;
	  touch-action: pan-y;
	  margin-left: calc(var(--slide-spacing) * -1);
	}
	&__slide {
	  flex: 0 0 var(--slide-size);
	  min-width: 0;
	  padding-left: var(--slide-spacing);
	  position: relative;
	}
	&__slide__img {
	  display: block;
	  height: var(--slide-height);
	  width: 100%;
	  object-fit: cover;
	}
	&__slide__number {
	  width: 4.6rem;
	  height: 4.6rem;
	  z-index: 1;
	  position: absolute;
	  top: 0.6rem;
	  right: 0.6rem;
	  border-radius: 50%;
	  background-color: rgba(var(--background-site-rgb-value), 0.85);
	  line-height: 4.6rem;
	  font-weight: 900;
	  text-align: center;
	  pointer-events: none;
	}
	&__slide__number > span {
	  color: var(--brand-primary);
	  background-image: linear-gradient(
		45deg,
		var(--brand-primary),
		var(--brand-secondary)
	  );
	  background-clip: text;
	  -webkit-background-clip: text;
	  -webkit-text-fill-color: transparent;
	  font-size: 1.6rem;
	  display: block;
	  position: absolute;
	  top: 0;
	  left: 0;
	  right: 0;
	  bottom: 0;
	}
  }