@use '../variables' as *;
@use '../apps/mixins/utilities';
@use "sass:map";

// **********************************************************
// LAYOUT
.announcements {
    &, &.ui.segment.basic {
        background-color: var(--#{$prefix}light);
        margin-bottom: 0;
        position: relative !important;
        .portalHeader & {
            margin-top: -1em;
            margin-bottom: 1em;
        }
    }
    &__wrapper {
        position: relative;
        padding: 0 calc(2.5rem - 1em);
        margin-top: 1rem;
    }
    .slideControls {
        display: block;
        width: 100%;
    }
    .slider__button {
        @include utilities.clean-button;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        color: var(--#{$prefix}body-color);
        z-index: $zindex-flat + 1;
        cursor: pointer;
        &__svg {
            height: 1rem;
            width: auto;
        }
        &--prev {
            left: -1rem;
        }
        &--next {
            right: -1rem;
        }
        &:hover, &:focus {
            color: var(--#{$prefix}primary-txt);
            background-color: var(--#{$prefix}white-bg);
        }
    }
    &__close {
        &, &.ui.button {
            @include utilities.clean-button;
            position: absolute;
            top: 0;
            right: 0;
            z-index: $zindex-flat + 1;
            color: var(--#{$prefix}primary-txt) !important;
            font-size: 1.25rem;
        }
    }
    .slider__container {
        align-items: center;
    }
    & + .portalHeader, 
    & + .portalHeader.ui.basic.segment {
        margin-top: 0;
    }

    // 576 pixels - 36em
    @media (min-width: map.get($grid-breakpoints, "sm")) {
        &__wrapper {
            padding-left: 5rem;
            padding-right: 5rem;
            margin-top: 0;
        }
        .slider__button {
            &--prev {
                left: 2.5rem;
            }
            &--next {
                right: 2.5rem;
            }
        }
        &__close {
            &, &.ui.button {
                top: 50%;
                transform: translateY(-50%);
            }
        }
    }
}

// **********************************************************
// ANNOUNCEMENT ITEM
.announcementItem {
    color: var(--#{$prefix}body-color);
    text-decoration: none;
    display: block;
    text-align: center;
    &__icon {
        color: var(--#{$prefix}primary-txt);
    }
}