@use '../variables' as *;
@use '../apps/mixins/utilities' as u;
@use "sass:map";

// **********************************************************
// TOGGLE BUTTON
.navBarToggle {
    @include u.clean-button;
    width: 2.5rem;
    height: 2.5rem;
    font-size: 1.5rem;
    color: var(--#{$prefix}white-txt);
    cursor: pointer;
}

.navigation {
    color: var(--#{$prefix}white-txt);

    .ui.menu {
        // **********************************************************
        // MENU MOBILE
        font-size: $h5-font-size;
        flex-direction: column;
        background-color: var(--#{$prefix}dark);
        border: none;
        border-radius: 0;
        box-shadow: none;
        padding-left: 1.5rem;
        padding-right: 1.5rem;

        .ui.inline.dropdown {
            &>.text {
                font-weight: $font-weight-normal;
            }

            &:hover,
            &:focus {
                color: var(--#{$prefix}body-color);
                background-color: var(--#{$prefix}white-bg);
            }
        }

        .item+.item {
            border-top: 1px solid var(--#{$prefix}white-bg);
        }

        .item {
            color: var(--#{$prefix}white-txt);
            padding: 2rem 0;
            border-radius: 0;

            &.active {
                display: flex;
                font-weight: $font-weight-bold;

                &::before {
                    content: '';
                    background-color: var(--#{$prefix}primary-bg);
                    height: 1em;
                    width: .25rem;
                    display: block;
                    position: static;
                    margin-right: .5rem;
                }
            }
        }
    }

    // **********************************************************
    // MENU DESKTOP
    // 992 pixels - 62em
    @media (min-width: map.get($grid-breakpoints, "lg")) {
        .ui.menu {
            flex-direction: row;
            align-items: center;
            background-color: transparent;

            .item+.item {
                border-top: none;
            }

            .item {
                border-right: 1px solid rgba(var(--#{$prefix}white-rgb), .66);
                padding: .25rem 1rem;
                flex: 1 1 auto;
                display: inline-flex;
                justify-content: center;
                align-items: center;
                text-align: center;

                &.active {
                    background-color: var(--#{$prefix}white-bg);
                    color: var(--#{$prefix}primary-txt);
                    font-weight: $font-weight-bold;

                    &::before {
                        content: none;
                    }
                }

                &:hover,
                &:focus {
                    color: var(--#{$prefix}body-color)
                }
            }

            .mnPrincipal__second .item.active {
                background-color: transparent;
            }
        }
    }

    // **********************************************************
    // SEGON NIVELL
    .ui.menu {
        .ui.dropdown.item {
            &>.dropdown.icon {
                font-size: 1em;
                // 992 pixels - 62em
                @media (min-width: map.get($grid-breakpoints, "lg")) {
                    margin-left: 0.25rem;
                }
            }

            .menu {
                background-color:var(--#{$prefix}dark-primary);
                border-radius: 0;
                right: auto;
                min-width: calc(100% + 1px);
                border: 0;

                div.item {
                    border-bottom: 1px solid var(--#{$prefix}white-bg);
                    padding: 0 !important;
                }

                .item {
                    border-right: 0;
                    color: var(--#{$prefix}white-txt);
                    text-align: left;
                    font-size: $h6-font-size;                    
                    &::before {
                        content: none;
                    }
                    a.item {
                        padding: 1em;
                        &:hover, &:focus {
                            background-color: var(--#{$prefix}white-bg);
                            color: var(--#{$prefix}body-color);
                        }
                    }
                }
            }

        }

        @media (max-width: calc(62rem - 1px)) {
            .item.active::before {
                content: none;
            }

            .ui.inline.dropdown:focus,
            .ui.inline.dropdown:hover {
                background-color: transparent;
                color: var(--#{$prefix}white-txt)
            }

            .ui.dropdown.item {
                flex-direction: column;
                align-items: flex-start;
                position: relative;

                &>.dropdown.icon {
                    display: none;
                }
                &::after {
                    content: '';
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath d='M201.4 374.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 306.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z' fill='%23ffffff' /%3E%3C/svg%3E");
                    background-size: contain;
                    background-repeat: no-repeat;
                    width: 1em;
                    height: 1em;
                    display: block;
                    position: absolute;
                    top: 1.75em;
                    right: .5em;
                }

                .menu {
                    margin-top: 1em;
                    position: relative;

                    a {
                        padding-top: 0;
                        padding-bottom: 0;
                        white-space: normal;
                    }
                }
            }
        }
    }
}

// **********************************************************
// NAVIGATION BUTTON TOGGLE

// 992 pixels - 62em
@media (min-width: map.get($grid-breakpoints, "lg")) {
    .navBarToggle {
        display: none !important; 
    }
}