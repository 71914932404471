@use '../variables' as *;
@use '../apps/utilities' as *;
@use "sass:map";


// **********************************************************
// SUMMARY
.summary-view {
    article.summary-view__item {
        margin-bottom: 3.5rem;
    }
    &__item {
        &__image {
            &, &.ui.image {
                width: 100%;
                margin-bottom: 1rem;
            }
        }
        // 576 pixels - 36em
        @media (min-width: map.get($grid-breakpoints, "sm")) {
            &--hasImage {
                display: flex;
                align-items: flex-start;
            }
            &__image {
                &, &.ui.image {
                    max-width: 12rem;
                    height: auto;
                    margin-bottom: 0;
                    margin-left: 1rem;
                }
            }
        }
        // 1280 pixels - 75em
        @media (min-width: map.get($grid-breakpoints, "xl")) {
            &__image {
                &, &.ui.image {
                    max-width: 25rem;
                    margin-left: 1.5rem;
                }
            }
        }
    }
}