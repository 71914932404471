@use '../../variables' as *;
@use "sass:map";

// **********************************************************
.ui.table {
    &.sortable {
        thead {
            th {
                position: relative;
                padding-right: 1.5em;
                &::after {
                    content: '';
                    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='24px' viewBox='0 -960 960 960' width='24px' fill='currentColor'%3E%3Cpath d='M320-440v-287L217-624l-57-56 200-200 200 200-57 56-103-103v287h-80ZM600-80 400-280l57-56 103 103v-287h80v287l103-103 57 56L600-80Z' /%3E%3C/svg%3E") no-repeat center center / contain transparent;
                    display: block;
                    width: 1.25em;
                    height: 1.25em;
                    position: absolute;
                    color: var(--#{$prefix}body-color);
                    right: 1em;
                    top: 50%;
                    transform: translateY(-50%);
                    opacity: .33;
                }
                &.ascending::after, &.descending::after {
                    content: '' !important;
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='24px' viewBox='0 -960 960 960' width='24px' fill='currentColor'%3E%3Cpath d='M440-80v-647L256-544l-56-56 280-280 280 280-56 57-184-184v647h-80Z'/%3E%3C/svg%3E");
                    opacity: 1;
                    width: 1em;
                    height: 1em;
                }
                &.descending::after {
                    transform: rotate(180deg) translateY(50%);
                }
            }
        }
    }
}