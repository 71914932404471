@use '../variables' as *;
@use "sass:map";

// **********************************************************
// WRAPPER
.secTramits {
    &, &.segment.basic {
        body.frontpage & {
            margin-bottom: 0;
            padding-bottom: 0;
        }
    }
    &__wrapper {
        background-color: var(--#{$prefix}dark);
        color: var(--#{$prefix}white-txt);
        padding: 3rem 0;
    }
    &__title {
        &, &.ui.header {
            justify-content: center !important;
            align-items: center; 
        }
    }
    &__search {
        display: flex;
        max-width: 100%;
        margin-left: auto;
        margin-right: auto;
        justify-content: center;
        margin-bottom: 1.4rem;
        &__input.ui.input {
            width: 36rem;
            input {
            padding-left: 1em;
            border-radius: 500em 0 0 500em;
        }}
        &__btn.ui.button {
            border-radius: 0 500em 500em 0;
        }
    }
    &__list {
        margin-bottom: 1.5rem;
        text-align-last: left;
        a {
            color: var(--#{$prefix}white-txt);
        }
        li{
            margin-bottom: 0.5em;
            &::marker {
                color: var(--#{$prefix}primary-bg);
            }
        }
        // 768 pixels - 48em
        @media (min-width: map.get($grid-breakpoints, "md")) {
            columns: 2;
            column-gap: 1.5rem;
            li {
                break-inside: avoid;
            }
        }
    }
}