@use '../variables' as *;
@use "sass:map";

// **********************************************************
//  BLOG ITEM
.blogItem {
    &__link {
        color: var(--#{$prefix}body-color);
        text-decoration: none;
        &:hover, &:focus {
            color: var(--#{$prefix}link-color);
            text-decoration: underline;
        }
    }
}

// **********************************************************
//  BLOG UNFOLDED
.blogUnfolded {
    &__item {
        margin-bottom: 3rem;
    }
}

.blogUnfoldedItem {
    &__header {
        display: block;
        position: relative;
        background-color: var(--#{$prefix}primary-bg);
        margin-bottom: 1rem;
    }
    &__img {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        mix-blend-mode: multiply;
        z-index: 0;
    }
    &__link {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-size: $h2-font-size;
        color: var(--#{$prefix}white-txt);
        text-decoration: none;
        font-weight: bold;
        padding: 1em;
        position: relative;
        z-index: 1;
        &:hover, &:focus {
            background-color: var(--#{$prefix}black);
            color: var(--#{$prefix}white-txt);
        }
    }
    &__children {
        margin-bottom: 1.5rem;
        &__link {
            display: flex;
            background-color: var(--#{$prefix}light);
            padding: 1rem;
            color: var(--#{$prefix}body-color);
            text-decoration: none;
            align-items: flex-start;
            &:hover, &:focus {
                background-color: var(--#{$prefix}primary-bg);
                color: var(--#{$prefix}white-txt);
            }
        }
        &__text {
            flex-grow: 1;
        }
        &__icon {
            flex: 0 0 auto;
            margin-left: 1rem;
        }
       
    }
}