@use '../variables' as *;
@use "sass:map";

// **********************************************************
// FRONTPAGE
.newsHome {
    &__wrapper {
        background-color: var(--#{$prefix}$gray-200);
        padding-top: 2rem;
    }

    &__title {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 1.5rem;

        .ui.header {
            margin-bottom: 0;
            text-align: center;
            justify-content: center;
            font-size: $h3-font-size;
        }
    }

    &__rss {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        margin-left: 1rem;
        font-size: 1rem;
        width: 2.5rem;
        height: 2.5rem;
        border-radius: 50%;
        color: var(--#{$prefix}white-txt);
        background-color: var(--#{$prefix}primary-bg);

        &:hover,
        &:focus {
            background-color: var(--#{$prefix}body-color);
            color: var(--#{$prefix}white-txt);
        }
    }
}

// **********************************************************
// NEWS ITEM
.newsItem {

    .column>&,
    .column>&.ui.card,
    &.ui.card {
        margin: 0;
        width: 100%;
        background-color: var(--#{$prefix}white-bg);
        border-radius: var(--#{$prefix}border-radius-lg) !important;
    }

    &--featured.ui.card {
        margin-bottom: 2rem;
    }

    .ui.card>&__img.image {
        width: 100%;
        height: auto;
        aspect-ratio: 16/9;
        object-fit: cover;
        border-radius: var(--#{$prefix}border-radius-lg) var(--#{$prefix}border-radius-lg) 0 0 !important;
    }

    &__text {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
    }

    &__title {

        &,
        &.header {
            text-align: left !important;
        }
    }

    &__link {
        color: var(--#{$prefix}body-color);
        text-decoration: none;

        &:hover,
        &:focus {
            color: var(--#{$prefix}primary-txt);
        }
    }

    &__desc {

        &,
        &.description {
            margin-bottom: 1rem !important;
            font-size: $font-size-base;
        }
    }

    &__meta {
        &.meta {
            color: rgba(var(--#{$prefix}dark-rgb), .66) !important;
        }

        &__list {
            font-weight: $font-weight-bold;
            color: var(--#{$prefix}primary-txt);
            font-size: $font-size-base;
        }
    }

    &__date {
        border-top: 1px solid var(--#{$prefix}primary-bg);
        display: block;
        text-align: right;
        padding-top: 0.5rem;
        font-style: italic;
    }

    // 768 pixels - 48em
    @media (min-width: map.get($grid-breakpoints, "md")) {
        &--featured.ui.card {
            flex-direction: row;
            border-radius:var(--#{$prefix}border-radius-lg) !important;

            &.ui.card>.newsItem__img.image {
                border-radius: var(--#{$prefix}border-radius-lg) 0 0 var(--#{$prefix}border-radius-lg) !important;
                flex: 0 0 50%;
                width: 50%;
            }

            .newsItem__text {
                flex: 1 1 50%;
            }

            .newsItem__meta {
                margin-top: auto;
            }

            .newsItem__title {

                &,
                &.header:not(.ui) {
                    font-size: $h3-font-size !important;
                }
            }
        }
    }
}

// **********************************************************
//  NEWSLISTING
body.newslisting .ui.basic.segment.content-area,
body.eventslisting .ui.basic.segment.content-area {
    margin-bottom: 0;
    padding-bottom: 0;
}

.newsListing {
    &__wrapper {
        background-color: var(--#{$prefix}gray-200);

    }
}

// **********************************************************
// PAGE 
.newsPage {
    &__img {
        padding: 0;
        margin: 0 0 1.5rem 0;

        .ui.image {
            width: 100%;
            height: auto;
        }

        &__caption {
            font-size: $font-size-sm;
            font-style: italic;
            opacity: .80;
        }
    }
}

// **********************************************************
// FILTER
.searchList {

    &,
    &.form.ui {
        position: relative !important;
        z-index: $zindex-dropdown + 1;

        .container {
            background-color: var(--#{$prefix}light);
            margin-bottom: 3rem;

            .fields {
                padding-left: 1rem;
                padding-right: 1rem;
            }

            // 992 pixels - 62em
            @media (min-width: map.get($grid-breakpoints, "md")) {
                .fields {
                    margin-bottom: 0 !important;
                }
            }
        }

        &__title {

            &,
            &.ui.header {
                width: 100%;
                padding: .5rem;
                background-color: var(--#{$prefix}gray-500);
                color: var(--#{$prefix}body-color);
            }
        }

        label {
            font-size: $h6-font-size;
            font-weight: $font-weight-bold;
        }

        .ui.input input[type="text"],
        .ui.selection.dropdown {
            border: 1px solid var(--#{$prefix}primary-bg);
            color: var(--#{$prefix}body-color);
            padding-left: .5rem;
            border-radius: var(--#{$prefix}border-radius);
            font-size: $font-size-base;
            height: 3rem;

            &::placeholder {
                color: rgba(var(--#{$prefix}dark-rgb), .66);
            }
        }

        .ui.selection.dropdown:not(.multiple):not(.search)>.dropdown.icon {
            color: var(--#{$prefix}primary-txt);
            margin-right: -0.5rem;
        }
    }

    // DATE
    &.ui.form &__date.field {
        flex: 0 0 auto !important;
        width: auto;

        input[type="text"] {
            width: 100%;
        }
    }

    .DateInput {
        width: 100%;
        min-width: calc(130px + 2.5rem);
    }

    .SingleDatePickerInput__showClearDate {
        padding-right: 0;

        input[type="text"] {
            padding-right: 2rem;
        }
    }

    .SingleDatePickerInput_clearDate {
        width: 2.5rem;
        height: 2.5rem;
        padding: 0;
        justify-content: center;
        align-items: center;
        display: flex;
    }

    @for $i from 1 through 10 {
        .field:nth-child(#{$i}) {
            z-index: $zindex-flat + (10 - $i);
            position: relative;

        }
    }

}

// **********************************************************
// PAGINATION
.pagination {

    &,
    &.ui.pagination.menu {
        padding-top: 1.5rem;
        padding-bottom: 2rem;
    }

    .ui.attached.menu {
        justify-content: center;
        border: none;
    }

    .ui.secondary.menu,
    &.menu.ui {
        align-items: stretch;

        .item {
            border: 1px solid var(--#{$prefix}primary-bg);
            color: var(--#{$prefix}primary-txt);
            height: 100%;
            margin-left: 0;
            margin-right: 0;
            border-radius: 0;
            &:not(.active) {
                background-color: var(--#{$prefix}white-bg) !important;
            }

            &:first-child {
                border-radius: var(--#{$prefix}border-radius-sm) 0 0 var(--#{$prefix}border-radius-sm);
            }

            &:last-child {
                border-radius: 0 var(--#{$prefix}border-radius-sm) var(--#{$prefix}border-radius-sm) 0;
            }

            &+.item {
                border-left-width: 0 !important;
            }

            &.active {
                background-color: var(--#{$prefix}primary-bg);
                color:var(--#{$prefix}white-txt);
            }

            &:hover,
            &:focus {
                color: var(--#{$prefix}body-color);
            }
        }
    }
}