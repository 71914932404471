@use '../../variables' as *;
@use "sass:map";

// **********************************************************
.listing-item {
    a {
        color: var(--#{$prefix}body-color);
        text-decoration: none;
        &:hover,
        &:focus {
            color: var(--#{$prefix}primary-txt);
            text-decoration: underline;
        }
    }
}
.pagination {
    
}