@use '../variables' as *;

// Set body properties with CSS custom variables
body {	 	
	color: var(--#{$prefix}body-color); 
	background-color: var(--#{$prefix}body-bg);
	font-size: $font-size-base;
	font-family: $font-family-sans-serif;
}

// Links

a {
	color: var(--#{$prefix}link-color); 
	text-decoration: underline;
	text-decoration-color: var(--#{$prefix}link-hover-color);
	&:hover, &:focus {
		color:var(--#{$prefix}link-hover-color);
	  	text-decoration-color: var(--#{$prefix}link-hover-color);
	}
  }


// Font family
body {
	h1, h2, h3, h4, h5, h6,
	.ui.header,
	.ui.button {
		font-family: $font-family-sans-serif;
	}
}