// Fix Volto and Plone errors
@use 'variables';

// **********************************************************
// SIDEBAR
#toolbar{
    .pastanaga-menu-list li a, 

    .pastanaga-menu-list li button {
        font-family: variables.$font-family-body;
    }
    a {
        text-decoration: none;
    }
    .toolbar {
        z-index: variables.$zindex-fixed;
    }
    .toolbar-content {
        z-index: 4;
    }
}

// **********************************************************
// FRONTPAGE
.ui.basic.segment.content-area { 
    margin-top: 0; 
    padding-top: 0; 
    body.frontpage & {
        margin-bottom: 0;
        padding-bottom: 0;
    }
}

// **********************************************************
// CONTROL PANEL
.controlpanel a,
.folder-contents a {
    text-decoration: none;
    color:var(--#{variables.$prefix}body-color);
}
#field-place_typoliogies {
    z-index: 1000; // Evitar que el desplagable de tipologies quedi sota el mapa
}

// **********************************************************
// GRID
.grid-image-wrapper img, 
.gridBlock .block.image img:not(.placeholder), 
.block-editor-gridBlock .block-editor-image img:not(.placeholder), 
.block-editor-image.contained img:not(.placeholder) {
    aspect-ratio: unset;
}

// **********************************************************
// TABLES
.ui.table {
    background-color: var(--#{variables.$prefix}white-bg);
    color: var(--#{variables.$prefix}body-color);
    thead th {
        color: var(--#{variables.$prefix}body-color);
        background-color: var(--#{variables.$prefix}light);
    }
    tr {
        border-left-color: var(--#{variables.$prefix}border-color);
    }
    &.celled{
        tr td, tr th {
        border-color: var(--#{variables.$prefix}border-color);
    }}
}