@use '../../variables' as *;

// **********************************************************
// LAYOUT
.block.listing {
	.headline {
        text-align: center;
        margin-bottom: 1.5rem;
        font-size: $h3-font-size;
		margin-top: 2rem;
    }
	.readMoreItem {
		padding-top: 1.5rem;
		text-align: center;
	}
}
.directLinks {
    padding-top: 2rem;

    &__title {
        text-align: center;
        margin-bottom: 1.5rem;
        font-size: $h4-font-size;
    }

    &__list {
		display: flex;
		flex-wrap: wrap;
		gap: .5rem;
		justify-content: center;
	}
	
}

// **********************************************************
// ITEM
.directLinksItem {
	display: flex;
	align-items: center;
	font-size: $h6-font-size;
	font-weight: $font-weight-bold;
	text-decoration: none;
	color: var(--#{$prefix}body-color);
	background-color: rgba(var(--#{$prefix}primary-rgb), .05);
	padding: .5rem;
	width: 14.5rem;
	&__icon {
		font-size: 3.5rem;
		line-height: 1;
		flex: 0 0 auto;
		color: currentColor;
		&__wrapper {
			margin-right: .5rem;
		}
	}
	&:hover, &_focus {
		background-color: rgba(var(--#{$prefix}primary-rgb), .88);
		color: var(--#{$prefix}white-txt);
	}
}