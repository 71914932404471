@use './variables' as *;

// Default font size
.ui.segments .segment,
.ui.segment {
    font-size: $font-size-base;
}
.ui.segment {
    border-color: var(--#{$prefix}border-color);
    background-color: var(--#{$prefix}white-bg);
}

// Aspecte dels botons
.ui.button {
    border-radius: var(--#{$prefix}border-radius-pill);
    font-weight: $font-weight-normal;
}

// Passar colors a variables CSS
.ui.inverted.header {
    color: var(--#{$prefix}white-txt);
}
.ui.cards > .card .meta, .ui.card .meta {
    color: var(--#{$prefix}gray-600);
}

// Default Font family
.ui.cards>.card>.content>.header,
.ui.card>.content>.header,
.ui.menu {
    font-family: $headings-font-family;
}

// Contrast del text als selects
.ui.dropdown:not(.button)>.default.text,
.ui.default.dropdown:not(.button)>.text {
    color: rgba(var(--#{$prefix}dark-rgb), .66)
}

// Color primary en els dropdown
.ui.dropdown.selected,
.ui.dropdown .menu .selected.item {
    color: var(--#{$prefix}primary-txt);
}
.ui.selection.dropdown {
    background-color: var(--#{$prefix}white-bg);
}

body .ui.form {
    .ui.input {

        input:not([type]),
        input[type="date"],
        input[type="datetime-local"],
        input[type="email"],
        input[type="number"],
        input[type="password"],
        input[type="search"],
        input[type="tel"],
        input[type="time"],
        input[type="text"],
        input[type="file"],
        input[type="url"] {
            font-family: $font-family-body;
            background-color: var(--#{$prefix}white-bg);
        }
    }

    textarea {
        font-family: $font-family-body;
    }
}
body .ui.input > input {
    font-family: $font-family-body;
}

p {
    line-height: $line-height-base;
}

ui.cards>.card .meta,
.ui.card .meta {
    color: var(--#{$prefix}gray-600);
}
.ui.card {
    background-color: var(--#{$prefix}white-bg);
}

// Set text color
.ui.header,
.ui.cards > .card > .content > .description, 
.ui.card > .content > .description,
.ui.checkbox label, 
.ui.checkbox + label { 
    color: var(--#{$prefix}body-color);
}

// Buttons
// body #main .ui.basic.button {
//     box-shadow: none !important;
// }

// Cookies
.gdpr-privacy-banner .ui.toggle.disabled.checkbox label,
.gdpr-privacy-banner .ui.toggle.checkbox input:focus:checked ~ label,
.gdpr-privacy-banner .ui.toggle.checkbox input:checked ~ label,
.gdpr-privacy-banner .ui.toggle.checkbox input:focus ~ label {
    color: var(--#{$prefix}body-color) !important;
 }
 .ui.toggle.checkbox .box, 
 .ui.toggle.checkbox label,
 .ui.form .field > label {
    color: var(--#{$prefix}body-color) !important;
 }

 // FORMS
 .ui.input.focus > input::placeholder, 
 .ui.input > input:focus::placeholder,
 .ui.input > input::placeholder {
    color: var(--#{$prefix}gray-600);
 }

 // Tables
 .ui.inverted.table {
    &, & th {
        color: var(--#{$prefix}white-txt) !important;
    }
}

// Cards
.ui.cards > .card > .extra, .ui.card > .extra {
    color: var(--#{$prefix}gray-600);
}

// **********************************************************
// FOCUS
@mixin outline-double-line {
	/* inner indicator */
	outline: 2px solid var(--#{$prefix}primary)  !important;
	outline-offset: 0;
	/* outer indicator */
	box-shadow: 0 0 0 6px var(--#{$prefix}white) !important;
}
*:focus-visible {	
	@include outline-double-line();
}